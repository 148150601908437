<template>
  <BaseBanner
    class="bloak-banner"
    close-btn-text-color="white"
    close-btn-color="white"
    banner-color="bloak-piggy-pink"
    banner-text-color="white"
  >
    <BaseSiteContent
      tag="h3"
      tag-class="header-2 text-md-center"
      content-key="global_banner_message"
      default-content="Come check us out."
    />

    <template #actions>
      <component
        :is="'BaseButton'"
        v-if="getSiteContentByKey('global_banner_btn_text')"
        color="white"
        text-color="bloak-piggy-pink"
        class="ml-0"
        small
        :to="getSiteContentByKey('global_banner_btn_route')"
        :href="getSiteContentByKey('global_banner_btn_link')"
      >
        {{ getSiteContentByKey('global_banner_btn_text') }}
      </component>
    </template>
  </BaseBanner>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalBanner',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteContentByKey']),
    bannerMessage() {
      return this.getSiteContentByKey('global_banner_message', false)
    },
  },
}
</script>

<style lang="scss">
.theme--light.global-banner {
  border-top: 1px solid rgb(255 255 255 / 60%);
  .header-2 {
    font-size: 20px !important;
    letter-spacing: 0.012em !important;
  }
}
</style>
